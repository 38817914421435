// src/ProfessionalDetail.jsx
import React, { useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DetailReservaProfile from './ProfessionalDetail/DetailReservaProfile';
import DetailReservaReviews from './ProfessionalDetail/DetailReservaReviews';
import DetailReservaGallery from './ProfessionalDetail/DetailReservaGallery';
import ReviewForm from './ProfessionalDetail/ReviewForm';
import ProfessionalReservationForm from './ProfessionalReservationForm';
import { auth } from './firebase';
import './ProfessionalDetail.css';
import { ChatContext } from './chat/ChatContext';

const ProfessionalDetail = () => {
  const { id: professionalId } = useParams();
  const user = auth.currentUser;
  const navigate = useNavigate();
  const { setProfessionalId } = useContext(ChatContext);

  // Estado para mostrar u ocultar el formulario en móvil
  const [showReservationFormMobile, setShowReservationFormMobile] = useState(false);

  const handleStartChat = () => {
    // Forzar reinicio del chat
    setProfessionalId(null);
    setTimeout(() => {
      setProfessionalId(professionalId);
    }, 0);
  };

  return (
    <div className="professional-detail-container">
      <div className="left-column">
        <div className="professional-detail-profile">
          {/* Se le pasan los props para controlar el botón de reserva en móvil */}
          <DetailReservaProfile
            professionalId={professionalId}
            showReservationFormMobile={showReservationFormMobile}
            setShowReservationFormMobile={setShowReservationFormMobile}
            handleStartChat={handleStartChat}
          />
        </div>

        <div className="professional-detail-gallery">
          <DetailReservaGallery professionalId={professionalId} />
        </div>
        <div className="professional-detail-reviews">
          <DetailReservaReviews professionalId={professionalId} />
        </div>
        {/* Este review-form se mantiene solo en pantallas md en adelante */}
        <div className="review-form hidden md:block">
          <ReviewForm professionalId={professionalId} />
        </div>
      </div>
  
      <div className="right-column hidden md:block">
        <div className="professional-detail-reservation-form">
          <ProfessionalReservationForm professionalId={professionalId} />
        </div>
      </div>
    </div>
  );
};

export default ProfessionalDetail;

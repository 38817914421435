// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, doc, updateDoc, addDoc, getDoc, onSnapshot, Timestamp, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, listAll } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBWQBwcjqiDXDwNT8u3if8GbTneZ1QYyRE",
  authDomain: "app-cleaning-9c173.firebaseapp.com",
  projectId: "app-cleaning-9c173",
  storageBucket: "app-cleaning-9c173.firebasestorage.app",
  messagingSenderId: "278405292829",
  appId: "1:278405292829:web:012cd38912e7fbac2d77f4",
  measurementId: "G-KZ2S8HNCMW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Función para agregar una nueva reserva
const addReservation = async (userId, professionalId, reservationData) => {
  try {
    const reservationRef = await addDoc(collection(db, 'reservations'), {
      ...reservationData,
      userId: userId, // Asigna el valor de userId
      professionalId: professionalId, // Asigna el valor de professionalId
      status: 'confirmed',
      createdAt: Timestamp.now() // Añadido para registrar la fecha de creación
    });

    // Añadir reserva a la subcolección del usuario
    const userReservationRef = doc(db, 'users', userId, 'reservations', reservationRef.id);
    await setDoc(userReservationRef, {
      ...reservationData,
      reservationId: reservationRef.id,
      status: 'confirmed',
      createdAt: Timestamp.now()
    });

    // Añadir reserva a la subcolección del profesional
    const professionalReservationRef = doc(db, 'professionals', professionalId, 'reservations', reservationRef.id);
    await setDoc(professionalReservationRef, {
      ...reservationData,
      reservationId: reservationRef.id,
      userId: userId,
      status: 'confirmed',
      createdAt: Timestamp.now()
    });

    console.log('Reserva almacenada en Firestore para usuario y profesional.');
  } catch (error) {
    console.error('Error al agregar la reserva en Firestore:', error);
    throw error; // Lanza el error para que sea capturado donde se llama la función
  }
};

// Función para obtener las reservas del usuario
const getUserReservations = async (userId) => {
  const q = query(collection(db, "users", userId, "reservations"));
  const querySnapshot = await getDocs(q);
  const reservations = [];
  
  for (const docSnapshot of querySnapshot.docs) {
    const reservationData = docSnapshot.data();
    
    // Obtener datos del profesional
    const professionalDoc = await getDoc(doc(db, "professionals", reservationData.professionalId));
    const professionalData = professionalDoc.exists() ? professionalDoc.data() : {};
    
    // Mapear campos inconsistentes
    const hours = reservationData.hours || reservationData.duration || 'N/A';
    const price = reservationData.price || reservationData.amount || 'N/A';
    const amountPaid = reservationData.amountPaid || reservationData.amountPaid || 'N/A';
    const date = reservationData.date || 'N/A';
    const time = reservationData.time || 'N/A';
    
    reservations.push({
      id: docSnapshot.id,
      reservationId: reservationData.reservationId || docSnapshot.id,
      professionalName: professionalData.name || "Desconocido",
      hours: hours,
      price: price,
      amountPaid: amountPaid,
      date: date,
      time: time,
      status: reservationData.status || 'N/A'
    });
    
    // Log para depuración
    console.log(`Reserva ID: ${docSnapshot.id}`);
    console.log(`Horas: ${hours}, Precio: ${price}, Monto Pagado: ${amountPaid}`);
  }
  
  return reservations;
};

// Función para obtener los profesionales
const getProfessionals = async () => {
  const professionalsRef = collection(db, 'professionals');
  const querySnapshot = await getDocs(professionalsRef);
  const professionals = [];
  querySnapshot.forEach((doc) => {
    professionals.push({ id: doc.id, ...doc.data() });
  });
  return professionals;
};

// Función para agregar un nuevo profesional
const addProfessional = async (professional) => {
  try {
    await setDoc(doc(db, "professionals", professional.userId), professional);
    console.log("Profesional añadido con ID: ", professional.userId);
  } catch (e) {
    console.error("Error añadiendo el profesional: ", e);
  }
};

// Función para obtener el perfil de un profesional
const getProfessionalProfile = async (professionalId) => {
  if (!professionalId) {
    throw new Error("El ID del profesional es requerido");
  }

  const professionalRef = doc(db, "professionals", professionalId);
  const professionalDoc = await getDoc(professionalRef);
  if (professionalDoc.exists()) {
    return professionalDoc.data();
  } else {
    throw new Error("El profesional no existe");
  }
};

// Función para actualizar el perfil profesional del usuario
const updateProfessionalProfile = async (userId, data) => {
  const profileRef = doc(db, "professionals", userId);
  await updateDoc(profileRef, data);
};

// Función para obtener las reservas de un profesional
const getProfessionalReservations = async (professionalId) => {
  const reservationsRef = collection(db, 'professionals', professionalId, 'reservations');
  const querySnapshot = await getDocs(reservationsRef);
  const reservations = [];

  for (const reservationDoc of querySnapshot.docs) {
    const reservationData = reservationDoc.data();
    const userDoc = await getDoc(doc(db, "users", reservationData.userId));
    const userData = userDoc.exists() ? userDoc.data() : {};
    reservations.push({
      ...reservationData,
      user: {
        name: userData.name || "Desconocido",
        photoURL: userData.photoURL || "",
        hours: reservationData.hours || 1 // Asegúrate de que hours esté definido, usa un valor predeterminado si es necesario
      }
    });
  }

  return reservations;
};

// Función para agregar una reseña y actualizar la valoración media del profesional
const addReview = async (review) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error("Usuario no autenticado");
    }
    const userName = user.email.split('@')[0]; // Extraer el nombre del correo electrónico
    const userPhotoURL = user.photoURL || ''; // Obtener la URL de la foto de perfil del usuario
    const reviewWithUserId = {
      ...review,
      userId: user.uid,
      userName: userName,
      userPhotoURL: userPhotoURL, // Incluir la URL de la foto de perfil
      timestamp: Timestamp.now(),
    };
    // Agregar la reseña a la colección "reviews"
    await addDoc(collection(db, "reviews"), reviewWithUserId);

    // Actualizar la valoración media del profesional
    const professionalRef = doc(db, "professionals", review.professionalId);
    const professionalDoc = await getDoc(professionalRef);

    if (professionalDoc.exists()) {
      const professionalData = professionalDoc.data();
      const totalReviews = professionalData.totalReviews || 0;
      const averageRating = professionalData.averageRating || 0;

      const newTotalReviews = totalReviews + 1;
      const newAverageRating = ((averageRating * totalReviews) + review.overallRating) / newTotalReviews;

      // Asignar rango basado en la valoración media
      let rank;
      if (newAverageRating >= 4.6) {
        rank = 'Platino';
      } else if (newAverageRating >= 4.0) {
        rank = 'Oro';
      } else if (newAverageRating >= 3.0) {
        rank = 'Plata';
      } else {
        rank = 'Bronce';
      }

      await updateDoc(professionalRef, {
        totalReviews: newTotalReviews,
        averageRating: newAverageRating,
        rank: rank,
      });
    }
  } catch (e) {
    console.error("Error añadiendo la reseña: ", e);
    throw e;
  }
};

// Función para obtener las reseñas de un profesional
const getReviews = async (professionalId) => {
  const reviewsQuery = query(collection(db, 'reviews'), where('professionalId', '==', professionalId));
  const querySnapshot = await getDocs(reviewsQuery);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

// Función para actualizar el estado de una reserva
const updateReservationStatus = async (reservationId, status) => {
  try {
    const reservationRef = doc(db, 'reservations', reservationId);
    await updateDoc(reservationRef, { status });
    console.log('Reserva actualizada con ID:', reservationId);
  } catch (e) {
    console.error('Error actualizando la reserva:', e);
    throw e;
  }
};

// Función para subir una imagen a Firebase Storage
const uploadProfileImage = async (file) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("Usuario no autenticado");
  }
  const storageRefPath = `profileImages/${user.uid}/${file.name}`;
  const storageRef = ref(storage, storageRefPath);
  await uploadBytes(storageRef, file);
  const downloadURL = await getDownloadURL(storageRef);
  return downloadURL;
};

// Función para eliminar una imagen de Firebase Storage
const deleteProfileImage = async (imageUrl) => {
  const storageRef = ref(storage, imageUrl);
  await deleteObject(storageRef);
};

// Función para actualizar el perfil profesional con la URL de la imagen
const updateProfessionalProfileImage = async (userId, imageUrl) => {
  const profileRef = doc(db, "professionals", userId);
  await updateDoc(profileRef, { imageUrl });
};

// Función para subir imágenes a la galería y actualizar el perfil del profesional
const uploadGalleryImages = async (images) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("Usuario no autenticado");
  }
  const imageUrls = [];
  for (const image of images) {
    const storageRefPath = `professionals_gallery/${user.uid}/${image.name}`;
    const storageRef = ref(storage, storageRefPath);
    await uploadBytes(storageRef, image);
    const imageUrl = await getDownloadURL(storageRef);
    imageUrls.push(imageUrl);
  }

  // Actualizar el perfil del profesional con los enlaces de las imágenes
  const profileRef = doc(db, "professionals", user.uid);
  await updateDoc(profileRef, { galleryImages: imageUrls });

  return imageUrls;
};

// Función para obtener las imágenes de la galería de un profesional
const getGalleryImages = async (professionalId) => {
  try {
    const profileRef = doc(db, "professionals", professionalId);
    const profileDoc = await getDoc(profileRef);
    if (profileDoc.exists()) {
      const profileData = profileDoc.data();
      return profileData.galleryImages || [];
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error obteniendo las imágenes de la galería: ", error);
    throw error;
  }
};

// Función para eliminar una imagen de la galería
const deleteGalleryImage = async (imageUrl) => {
  const imageRef = ref(storage, imageUrl);
  await deleteObject(imageRef);
};

// Función para suscribirse a las reseñas de un profesional
const subscribeToReviews = (professionalId, callback) => {
  const reviewsQuery = query(collection(db, 'reviews'), where('professionalId', '==', professionalId));
  return onSnapshot(reviewsQuery, (querySnapshot) => {
    const reviews = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    callback(reviews);
  });
};

// Función para obtener la disponibilidad de un profesional
const getProfessionalAvailability = async (professionalId) => {
  const professionalRef = doc(db, "professionals", professionalId);
  const professionalDoc = await getDoc(professionalRef);
  if (professionalDoc.exists()) {
    return professionalDoc.data().availability || {};
  } else {
    return {};
  }
};

// Función para actualizar la disponibilidad de un profesional
const updateProfessionalAvailability = async (professionalId, availability) => {
  const professionalRef = doc(db, "professionals", professionalId);
  await updateDoc(professionalRef, { availability });
};

// Función para obtener los horarios bloqueados de un profesional
const getBlockedTimes = async (professionalId) => {
  const professionalRef = doc(db, "professionals", professionalId);
  const professionalDoc = await getDoc(professionalRef);
  if (professionalDoc.exists()) {
    return professionalDoc.data().blockedTimes || [];
  } else {
    return [];
  }
};

// Función para actualizar los horarios bloqueados de un profesional
const updateBlockedTimes = async (professionalId, blockedTimes) => {
  const professionalRef = doc(db, "professionals", professionalId);
  await updateDoc(professionalRef, { blockedTimes });
};

// Llama a esta función después de que se confirme el pago
const handlePaymentConfirmation = async (professionalId, reservation) => {
  // Aquí iría el código para confirmar el pago
  // ...

  // Después de confirmar el pago, añade la reserva al profesional
  // await addReservationToProfessional(professionalId, reservation); // Esta línea ha sido eliminada
};

// Función para observar el estado de autenticación
const observeAuthState = (callback) => {
  return onAuthStateChanged(auth, callback);
};

// Función para registrar un nuevo administrador
const registerAdmin = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    await setDoc(doc(db, 'admin', user.uid), { email });
    console.log('Administrador registrado con éxito:', user.uid);
    return user;
  } catch (error) {
    console.error('Error al registrar el administrador:', error);
    throw error;
  }
};

// Función para iniciar sesión como administrador
const loginAdmin = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    console.log('Administrador logueado con éxito');
    return userCredential.user;
  } catch (error) {
    console.error('Error al iniciar sesión como administrador:', error);
    throw error;
  }
};

// Exportar funciones necesarias
export {
  observeAuthState,
  auth,
  db,
  addReservation,
  getUserReservations,
  getProfessionals,
  addProfessional,
  getProfessionalProfile,
  getProfessionalReservations,
  addReview,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateReservationStatus,
  uploadProfileImage,
  deleteProfileImage,
  updateProfessionalProfileImage,
  updateProfessionalProfile,
  uploadGalleryImages,
  getGalleryImages,
  deleteGalleryImage,
  subscribeToReviews,
  getProfessionalAvailability,
  updateProfessionalAvailability,
  getReviews,
  handlePaymentConfirmation,
  getBlockedTimes,
  updateBlockedTimes,
  registerAdmin,
  loginAdmin,
  storage
};
